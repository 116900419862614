<template>
  <div class="GroupOverview">
    <!-- banner图和导航栏 -->
    <div class="banner_nav">
      <!-- 导航栏 -->
      <div class="navWrap">
        <div class="navWrap_center">
          <div class="navWrap_center_img">
            <img
              style="width: 80px"
              src="../assets/img/productImg/logoPic.png"
              alt=""
            />
            <img src="../assets/img/productImg/logoContent.png" alt="" />
          </div>

          <ul class="navWrap_center_ul">
            <li
              v-for="(item, index) in navList"
              :key="index"
              @click="funyuming(item.url, index)"
              :class="index === activeIndex ? 'activeLi' : ''"
            >
              {{ item.title }}
            </li>
            <li>
              <el-input
                v-model="title"
                placeholder="请输入产品名称"
                @input="keyUpSearch"
              ></el-input>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 我的产品 -->
    <div class="GroupIndustry">
      <div class="GroupIndustry_cont">
        <ul style="width: 100%; display: flex; justify-content: space-around">
          <li
            class="ProductLiTitle"
            @click="changeProduct(index, item.id)"
            :class="index === ProductActiveIndex ? 'ProductLiTitleActive' : ''"
            v-for="(item, index) in productList"
            :key="index"
          >
            {{ item.moduleValue }}
          </li>
        </ul>
        <div class="productContent" v-if="productImgList.length">
          <div
            @click="view(item)"
            class="productImgHover"
            v-for="(item, index) in productImgList"
            :key="index"
          >
            <img
              style="width: 100%; height: 350px"
              :src="`https://api.panda-gzsap.com/picture/download/${item.imgName}`"
              alt=""
            />
            <div
              style="
                margin: 6px 0 20px 0;
                color: #888;
                font-size: 14px;
                padding-left: 8px;
              "
            >
              <p class="textStyle">NAME：{{ item.title }}</p>
              <p>PART NO.：{{ item.productCode }}</p>
              <p>OEM NO.：{{ item.originalCode }}</p>
            </div>
          </div>
          <div style="width: 100%; margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            />
          </div>
        </div>
        <div v-else class="productEmpty">暂无数据</div>
      </div>
    </div>
    <img
      v-show="showBackUp"
      class="showBackUp"
      @click="backToTop"
      src="../assets/img/productImg/backUp.jpg"
      alt=""
    />
    <div class="dialog" v-show="dialogVisible">
      <div class="dialogContent">
        <div class="dialogTitle">
          {{ productList[ProductActiveIndex].moduleValue }}
        </div>
        <img
          style="position: absolute; right: 15px; top: 10px; cursor: pointer"
          @click="dialogVisible = false"
          src="../assets/img/productImg/delIcon.jpg"
          alt=""
        />
        <div class="diaContent"><img :src="productImgSrc" alt="" /></div>
        <div
          style="
            margin: 0 10px 20px 10px;
            color: #888;
            font-size: 14px;
            padding-left: 8px;
          "
        >
          <p>NAME：{{ productTitle }}</p>
          <p>PART NO.：{{ productCode }}</p>
          <p>OEM NO.：{{ originalCode }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPage, getDictKeyValue } from "@/api/index";
export default {
  name: "App",
  data() {
    return {
      title: "",
      total: 0,
      currPage: 1,
      pageSize: 10,
      searchValue: "",
      productTitle: "",
      productCode: "",
      originalCode: "",
      productImgSrc: "",
      productId: "",
      showBackUp: false,
      dialogVisible: false,
      productImgList: [],
      productList: [],
      activeIndex: 1,
      ProductActiveIndex: 0,
      navList: [
        { title: "Home", url: "/", showFlag: true },
        { title: "Products", url: "/jituangaikuo", showFlag: true },
        { title: "editNet", url: "/editDetail", showFlag: true },
        { title: "Contacts", url: "/lianxi", showFlag: true },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.query.index) {
        this.ProductActiveIndex = Number(this.$route.query.index);
      }
      if (this.$route.query.title) {
        this.$set(this, "title", this.$route.query.title);
      }
      this.ajaxLoad();
    });
  },
  methods: {
    keyUpSearch(val) {
      this.title = val.trim();
      this.currPage = 1;
      this._getPage();
    },
    ajaxLoad() {
      // 获取产品的内容
      getDictKeyValue({ dictId: "1" }).then((res) => {
        if (res.code == 200) {
          this.productList = res.data || [];
          this.productId =
            (res.data && res.data.length && res.data[0].id) || "";
          this._getPage();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查看产品
    view(row) {
      this.dialogVisible = true;
      this.productTitle = row.title;
      this.productCode = row.productCode;
      this.originalCode = row.originalCode;
      this.productImgSrc = `https://api.panda-gzsap.com/picture/download/${row.imgName}`;
    },
    backToTop() {
      // window.scrollTo(0,0)
      let myTimer = -1;
      let scrollTo =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (myTimer == -1) {
        myTimer = setInterval(() => {
          scrollTo -= 60;
          if (scrollTo <= 0) {
            scrollTo = 0;
            window.clearInterval(myTimer);
            myTimer = -1;
          }

          window.scrollTo(0, scrollTo); //这是值  是指离开网页顶部的距离
        }, 20);
        if (document.querySelector(".showBackUp")) {
          document.querySelector(".showBackUp").style.display = "none";
        }
      }
    },
    // 切换产品列表
    changeProduct(index, id) {
      this.ProductActiveIndex = index;
      if (this.productId == id) {
        return;
      } else {
        this.productId = id;
        this.productImgList = [];
        this._getPage();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this._getPage();
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this._getPage();
    },
    _getPage() {
      const params = {
        currPage: this.currPage,
        pageSize: this.pageSize,
        type: this.productId,
        title: this.title,
      };
      getPage(params).then((res) => {
        if (res.code == 200) {
          this.productImgList = res.data.rows || [];
          this.total = res.data.totalCount;
        }
      });
    },
    funyuming(msg, index) {
      if (this.activeIndex === index) {
        return;
      }
      this.activeIndex = index;
      this.$router.push(msg);
      this.title = "";
    },
  },
};
</script>

<style scoped>
.showBackUp {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 20px;
  right: 10px;
  cursor: pointer;
}
.dialog {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.diaContent {
  padding: 20px;
  padding-bottom: 10px;
}
.diaContent img {
  height: 350px;
  width: 350px;
}
.dialogTitle {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.dialogContent {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%);
  width: 390px;
  background-color: #fff;
  z-index: 101;
}
.GroupOverview {
  width: 100vw;
  margin: 0 auto;
  margin-top: 70px;
}
.GroupIndustry {
  width: 100vw;
  background: #ffffff;
}
.GroupIndustry_cont {
  width: 90%;
  margin: 100px auto;
}
.productEmpty {
  display: flex;
  justify-content: center;
  margin-top: 90px;
}
.productContent {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 20px;
}
.productImgHover {
  border-radius: 8px;
  margin: 10px 20px 0 0;
  position: relative;
  width: 350px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.4);
}
.textStyle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hoverImgContent {
  position: absolute;
  background: rgba(152, 200, 85, 0.3);
  bottom: 5px;
  width: calc(100% - 2px);
  height: 50px;
  display: flex;
  font-size: 14px;
  margin: 0px 3px;
  border-radius: 0 0 4px 4px;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  color: #89d961;
}
.ProductLiTitle {
  font-size: 18px;
  cursor: pointer;
  padding-bottom: 10px;
}
.ProductLiTitleActive {
  font-weight: bold;
  color: #89d961;
  border-bottom: 2px solid #89d961;
}
.ProductLiTitle:hover {
  cursor: pointer;
  color: #89d961;
  border-bottom: 2px solid #89d961;
}
/* 导航部分 */

.navWrap {
  /* transition: all .3s linear; */
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  color: #fff;
}

.navWrap_center {
  width: 100%;
  height: 70px;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.navWrap_center_img {
  /* width: 181px; */
  display: flex;
  align-items: center;
  height: 54px;
}

.navWrap_center_img img {
  width: 180px;
  height: 40px;
}

.navWrap_center_ul {
  padding-right: 20px;
}
.navWrap_center_ul li {
  display: inline-block;
  margin-left: 50px;
  padding: 10px 5px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
.activeLi {
  color: #89d961;
  border-bottom: 2px solid #89d961;
}
.navWrap_center_ul li:hover {
  cursor: pointer;
  color: #89d961;
  border-bottom: 2px solid #89d961;
}
</style>
